body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--semi-color-text-0);
    background-color: var(--semi-color-bg-0);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.markdown-body pre code {
    padding: 10px;
}

#root .repo-readme .markdown-body {
    background-color: unset;
}

#root .repo-list .semi-button-group-line,
#root .repo-list .semi-button-group-line-light,
#root .repo-list .semi-button-group-line::before,
#root .repo-list .semi-button-light:not(.semi-button-disabled):active,
#root .repo-list .semi-button-light:not(.semi-button-disabled):hover {
    background-color: unset;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

::-webkit-scrollbar-track-piece {
    background-color: none;
    background: rgba(0, 0, 0, .1);
}

::-webkit-scrollbar-thumb:vertical {
    height: 2px;
    background-color: rgba(var(--semi-grey-4), 1);
}

::-webkit-scrollbar-thumb:horizontal {
    width: 2px;
    background-color: rgba(var(--semi-grey-4), 1);
}