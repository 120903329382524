* {
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-family: "Microsoft YaHei", "ui-monospace", Hack, 微软雅黑, monospace, arial;
}

html,
body {
    margin: 0;
    height: 100%;
    overflow: hidden;
    user-select: none;
}

.blur {
    backdrop-filter: blur(300px);
    height: 100%;
    overflow: hidden;
    width: 100%;
    display: block;
    /* background-color: #ffffff11; */
    position: fixed;
    top: 0;
    z-index: -1;
    /* box-shadow: 10px 10px 5px #888888; */
}

.sider {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
    padding-right: 5px;
    /* color: #444; */
    text-align: center;
    background-color: transparent;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    will-change: backdrop-filter;
    background-color: rgba(var(--semi-grey-2), 1);
}

.box {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -100;
}

#root .semi-layout-sider {
    min-width: 220px;
}

.semi-select-selection {
    line-height: 18px;
}

#root {
    height: 100%;
}

#root .semi-layout {
    height: 100%;
}

.col-content div {
    text-align: center;
    /* color: #646464; */
}

.col-content.active div {
    color: #1f6feb;
}

.col-content>div:first-child {
    margin-top: 5px;
    font-size: 15px;
    padding: 3px;
}

.col-content svg {
    font-size: 20px;
}

#root .semi-navigation {
    width: 215px;
    border-right: unset;
    background-color: unset;
    overflow-y: auto;
    height: calc(100% - 250px);
}

#root .semi-navigation::-webkit-scrollbar {
    width: 0 !important;
    display: none;
    color: #0d1117;
}

.nav .semi-navigation-list>.semi-navigation-item,
.nav .semi-navigation-list>.semi-navigation-item>.semi-navigation-sub-title {
    font-weight: normal;
}

.repo-list {
    border-right: 1px solid var(--semi-color-border);
    overflow: hidden;
}

.list-box {
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    /*width: 26px;*/
    /* border-top: 1px solid rgba(var(--semi-grey-9), .08); */
}



#root .repo-list button {
    width: 33%;
    background-color: unset;
}

.repo-list .active {
    background-color: rgba(var(--semi-grey-3), 1);
}

.repo-list .semi-list-item {
    padding: 10px 15px;
}

.repo-list .list-item-footer {
    padding: 3px 1px;
    width: 100%;
    font-size: 11px;
    /* color: darkgrey; */
}

.repo-list .list-item-footer>span {
    width: 49%;
    display: inline-block;
}

.markdown-body {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 15px 30px;
}

.markdown-body .semi-tagInput-wrapper p {
    margin-bottom: 0;
}

#root .repo-readme .markdown-body {
    font-size: 13px;
}


/* #root .repo-readme .markdown-body .highlight pre,
#root .repo-readme .markdown-body pre {
    margin: 0
} */

.list-box .semi-spin-wrapper {
    margin-top: 30px;
}

.repo-description {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--semi-color-text-2);
    margin: 4px 0;
    text-align: left;
    font-size: 12px;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.repo-name {
    white-space: nowrap;
    color: var(--semi-color-text-0);
    font-weight: 550;
    font-size: 14px;
    text-align: left;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

[data-tauri-drag-region] {
    cursor: grab;
}

[data-tauri-drag-region]:active {
    cursor: grabbing;
}

#root .nav .semi-navigation-sub-wrap .semi-navigation-sub-title-text,
#root .nav .semi-navigation-item-text {
    text-align: left;
    width: 100%;
}

.login {
    /* padding-top: 200px; */
    background-image: url(https://assets.codehub.cn/micro-frontend/login/f7eeecbeccefe963298c23b54741d473.png);
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
}

.login .tokenInput {
    backdrop-filter: blur(30px);
    width: 400px;
    height: 30px;
    padding: 20px;
    margin-top: 30px;
    left: calc(50% - 195px);
    border: 1.5px solid #fff;
    border-radius: 8px;
    background: hsla(0, 0%, 100%, .5);
    position: absolute;
    z-index: 1000;
}

.login>img {
    position: absolute;
    user-select: none;
}

.login .img1 {
    max-width: 568px;
    left: 0;
    bottom: 0;
}

.login-box {
    width: 300px;
    position: relative;
    margin: 0 auto;
    text-align: center;
}


.login .img2 {
    max-width: 320px;
    right: 0;
    top: 0;
}


.repo-list,
.repo-readme {
    /* background-color: #ffffff; */
}

.semi-navigation-item-icon-info {
    margin-top: 1px;
}

.github-login-btn {
    margin-top: 100px;
    background-color: #24292e;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
}

.github-login-btn:hover {
    background-color: #2c333a;
}

.github-login-btn img {
    height: 20px;
    margin-right: 10px;
    vertical-align: middle;
}